// eslint-disable-next-line import/no-unresolved
import Keycloak from 'keycloak-js';

const keycloakConfig = {
 url: 'https://sso.cleversystems.com.br',
 realm: 'Dashboard',
 clientId: 'dashboard-front',
 // clientSecret: 'EVplmMAirEXsICGMZw99CmnV4uVe0gq3'
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;