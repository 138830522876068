import { create } from 'zustand';
import api from '../service/api';

const requestProfile = async () => {
  const user = JSON.parse(localStorage.getItem('User'));
  const userEmail = user?.useremail;
  if (userEmail !== undefined) {
    //  retornar o grupo
    // const responsePerma = await api.get(`/userperfis/${userEmail}`);
    // const responsePerma = await api.get(`/userperfis/gustavo.fonseca@cleversystems.com.br`);
    const responsePerma = await api.get('userperfis');
    return responsePerma.data.data.user;
  }
  return null;
};

export const userStore = create((set, get) => ({
  user: null,
  setUserNull: () => {
    set({ user: null });
  },
  getUser: async () => {
    let userState = get().user;
    if (userState === null) {
      userState = await requestProfile();
      set({ user: userState });
      return userState;
    }
    return userState;
  },
}));
