import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// protected routes
import { useKeycloak } from '@react-keycloak/web'

// pages
import NotFound from '../pages/Page404';

import BaseLayout from '../layouts/BaseLayout';

import productsRoutes from '../productsController/routes';

// import ProtectedRoutes from './ProtectedRoutes';
import ProtectedKeycloakRoutes from './ProtectedKeycloakRoutes';

import { loginRoutes, adminRoutes } from './defaultRoutes';

// ----------------------------------------------------------------------

const Home = lazy(() => import('../pages/Dashboard/Dashboard'));
const Alerts = lazy(() => import('../pages/Alerts'));

const baseProductUrl = '/products';

export default function Router() {
  const { initialized } = useKeycloak()

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
    <Routes>
      {loginRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}
      {/* <Route element={<ProtectedRoutes />}> */}
      <Route element={<ProtectedKeycloakRoutes />}>
        <Route path="/">
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/404" element={<NotFound />} />
        </Route>

        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<BaseLayout loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/alerts"
          element={
            <Suspense fallback={<BaseLayout loading />}>
              <Alerts />
            </Suspense>
          }
        />
        {adminRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
        {productsRoutes.map((route) => (
          <Route key={route.path} path={`${baseProductUrl + route.path}`} element={<route.component />} />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
}
