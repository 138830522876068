// scroll bar
import 'simplebar/src/simplebar.css';
// React
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// i18n
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import i18n from './i18n';
// App
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// import IdleTimer from './components/IdleTimer';
// import LogoutTime from './components/LogoutTime';
import keycloak from './routes/keycloak';




const initOptions = { onLoad: 'login-required' };
// const initOptions = { onLoad: 'check-sso' };



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename="/">
          {/* <LogoutTime /> */}
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
