import { useLayoutEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Avatar } from '@mui/material';
// i18n
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
// components
import { useKeycloak } from '@react-keycloak/web';
import MenuPopover from '../../components/MenuPopover';
// mocks_
//
import { stopRefresh } from '../../utils/refreshToken';
// store
import { userStore } from '../../store/userStore';
import { usePermission } from '../../store/usePermission';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const [user, setUser] = useState(null);
  // const [user, setAccountCache] = useState(null);
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();

  const { t } = useTranslation();
  const setUserNull = userStore((state) => state.setUserNull);
  const setPremissionNull = usePermission((state) => state.setPermissionsNull);

  const queryClient = useQueryClient();

  const { iconpath } = JSON.parse(localStorage.getItem('User'));

  const fetchUser = async () => {
    setTimeout(() => {
      const accountCache = queryClient.getQueryData(['account']);
      if (user === null || user === undefined) {
        setUser(accountCache);
      }
    }, 500);
  };

  useLayoutEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MENU_OPTIONS = [
    {
      label: i18next.t('profile-menu.start'),
      icon: 'eva:home-fill',
      linkTo: '/',
    },
    {
      label: i18next.t('profile-menu.profile'),
      icon: 'eva:person-fill',
      linkTo: `/perfil/${user?.idusuario}`,
    },
  ];
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = () => {
    const themebackup = localStorage.getItem('darkMode');
    setUserNull();
    setPremissionNull();
    queryClient.clear();
    localStorage.clear();
    localStorage.setItem('darkMode', themebackup);
    stopRefresh();
    try {
      keycloak.logout();
      navigate('/login', { replace: true });
    } catch (e) {
      console.log(e)
    }
    // navigate('/login', { replace: true });
  };

  return (
    <>
      <>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Avatar src={iconpath} alt={user?.usuarionome} />
        </IconButton>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            p: 0,
            mt: 1.5,
            ml: 0.75,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {user?.usuarionome}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user?.usuarioemail}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                state={{ id: user?.idusuario }}
                component={RouterLink}
                onClick={handleClose}
              >
                {option.label}
              </MenuItem>
            ))}
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
            {t('profile-menu.exit')}
          </MenuItem>
        </MenuPopover>
      </>
    </>
  );
}
