import { useEffect, useState } from 'react';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, Divider, IconButton } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// hooks
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useQueries } from 'react-query';
// sidebar
import { CLOSED_SIDEBAR_WIDTH, NAVBAR_HEIGHT } from '../../theme/sizes';
import { navConfig, adminNav } from './NavConfig';
// components
import NavSection from './NavSection';
import Scrollbar from '../../components/Scrollbar';
import version from '../../_mock/version';
// api
import api from '../../service/api';

// store
// import { usePermission } from '../../store/usePermission';

// import logoCleverCubo from '../../assets/logos-clever/logo-clever-cubo.svg';
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const drawerWidth = 340;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: CLOSED_SIDEBAR_WIDTH(theme),
  },
});

const DrawerHeader = styled(Box)(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: open ? 'flex-end' : 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: `${NAVBAR_HEIGHT} !important`,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ICONSIZE = 'medium';

// ----------------------------------------------------------------------

// export function reaload() {
//   window.location.reload(false);
// }

export default function DashboardSidebar({ open, setOpen }) {
  const [nav, setNav] = useState([]);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const user = JSON.parse(localStorage.getItem('User'));
  const userEmail = user?.useremail;

  const fetchData = async () => {
    const responsePerma = await api.get(`/userperfis/${userEmail}`);

    return responsePerma.data.data.user;
  };

  const fetchDataVersionBack = async () => {
    const response = await api.get(`/version`);
    return response.data.data;
  };

  const results = useQueries([
    { queryKey: ['versionBack'], queryFn: fetchDataVersionBack, staleTime: Infinity },
    {
      queryKey: ['account'],
      queryFn: () => fetchData(),
      enabled: !!userEmail,
      staleTime: Infinity,
    },
  ]);

  const sideBarPermission = async () => {
    const initialNavConfig = navConfig();
    const adminNavConfig = adminNav();

    const userPermissions = await results[1]?.data;

    if (userPermissions) {
/*       const isAdmin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
      const isGerente = userPermissions.roles.some((role) => role.perfilnome === 'Gerente');
      const isComum = userPermissions.roles.some((role) => role.perfilnome === 'Comum'); */

      // if (isGerente || isAdmin) {
      setNav(initialNavConfig.concat(adminNavConfig));
      // }
      // if (isComum) {
      setNav(initialNavConfig);
      // }
    }
    setNav(initialNavConfig.concat(adminNavConfig));
  };

  useEffect(() => {
    sideBarPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results[1].data, t]);

  const renderContent = (
    <Scrollbar
      sx={{
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
      }}
    >
      {/* <GoBackButtonStyle
        sx={{
          opacity: !navigateBack && 0,
          pointerEvents: !navigateBack && 'none',
        }}
        onClick={() => navigate(navigateBack === true ? -1 : navigateBack)}
      >
        <Icon icon={GoBackIcon} fontSize={32} color={theme.palette.text.primary} />
      </GoBackButtonStyle> */}
      <NavSection navConfig={nav} isdraweropen={open} handledraweropen={handleDrawerOpen} />

      {open && (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-end', padding: 5 }}>
          <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            {t('version')} Frontend: {version.number}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            {t('version')} Backend: {results[0].data.version}
          </Typography>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader open={open}>
          {open ? (
            <>
              <Box sx={{ width: 261, display: 'flex', justifyContent: 'center' }}>
                <Logo />
              </Box>
              <IconButton onClick={handleDrawerOpen} sx={{ color: 'inherit', justifySelf: 'flex-end' }}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon fontSize={ICONSIZE} />
                ) : (
                  <ChevronLeftIcon fontSize={ICONSIZE} />
                )}
              </IconButton>
            </>
          ) : (
            <IconButton
              size="small"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: 0,
              }}
            >
              <MenuIcon fontSize={ICONSIZE} />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        {renderContent}
      </Drawer>
    </Box>
  );
}
