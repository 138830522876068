import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

// import api from '../service/api';


const ProtectedKeycloakRoutes = () => {
  const { keycloak, initialized } = useKeycloak();
  const [userInfo, setUserInfo] = useState({});

  // Enquanto não inicia a sessão, deve mostrar loading...
  // eslint-disable-next-line no-unused-expressions
  !initialized && <div>Loading...</div>

  // Busca informações do usuário e grava no estado userInfo
  const fetchUserInfo = useCallback(async () => {
    if (initialized && keycloak.authenticated) {
      const { name, iconpath, email } = await keycloak.loadUserInfo();
      setUserInfo({
        name,
        iconpath,
        email
      });
    }
  }, [initialized, keycloak]);

  useEffect(() => {
    fetchUserInfo();
  }, [initialized, keycloak, fetchUserInfo]);

  // Senão estiver logado, redireciona para fazer login
  if (keycloak.authenticated) {
    const userStr = JSON.stringify({
      useremail: userInfo.email,
      iconpath: userInfo.iconpath,
    });
    const kcToken = keycloak?.token ?? '';
    localStorage.setItem('token', `Bearer ${kcToken}`);
    localStorage.setItem('User', userStr);
    return (
      <Outlet />
    );
  }
  return <div>Loading 123...</div>


  // eslint-disable-next-line no-nested-ternary
  // return keycloak.authenticated === null ? <> </> : keycloak.authenticated ? <Outlet /> : <Navigate to="/dashboard" />;
};

export default ProtectedKeycloakRoutes;
